import BookingCheckoutMutation from "../mutation/BookingCheckoutMutation";
import {
    setBookingDataAction,
    setContactDataAction,
    setCouponMessageAction,
    setUserDataAction
} from "../../store/Actions/BookingAction";
import {store} from "../../index";
import BookingCompleteMutation from "../mutation/BookingCompleteMutation";
import SignupMutation from "../mutation/SignupMutation";
import VerifyOtpMutation from "../mutation/VerifyOtpMutation";
import ResendOtpMutation from "../mutation/ResendOtpMutation";
import {setAuthType, setProfileData, setTokenData} from "../../store/Actions/AuthActions";
import { setLoadingStateAction } from "../../store/Actions/CommonAction";
import LoginMutation from "../mutation/LoginMutation";
import cogoToast from "cogo-toast";
import {clearOnCheckout, postBookingDataAction, setSelectedBookingIdAction} from "../../store/Actions/CommonAction";
import ReleaseSeatsMutation from "../mutation/ReleaseSeatsMutation";
import {CustomToast} from "../../components/CustomToast/CustomToast";
import CancelBookingMutation from "../mutation/CancelBookingMutation";
import BookingRescheduleMutation from "../mutation/BookingRescheduleMutation";
import BookingRescheduleCompleteMutation from "../mutation/BookingRescheduleCompleteMutation";
import CancelBookingSinglePassengerMutation from "../mutation/CancelBookingSinglePassengerMutation";
import ApplyCouponMutation from "../mutation/ApplyCouponMutation";
import ModifyBookingMutation from "../mutation/ModifyBookingMutation";
import DownloadAppMutation from "../mutation/DownloadMobileAppMutation";

import ApplyDefaultCouponMutation from "../mutation/ApplyDefaultCouponMutation";
import RemoveDiscountMutation from "../mutation/RemoveDiscountMutation";
import {api} from "../../utils/constants";
import { hashAndEncode , verifyHaptikAndSignUp} from "../../utils/utils"
import UpdateProfileMutation from "../mutation/UpdateProfileMutation";
import AddMoneyToWalletMutation from "../mutation/AddMoneyToWalletMutation";
import ApplyWalletMutation from "../mutation/ApplyWalletMutation";
import RemoveWalletFromBookingMutation from "../mutation/RemoveWalletFromBookingMutation";
import CreateCoPassengerMutation from "../mutation/CreateCoPassengerMutation";
import DeleteCoPassengerMutation from "../mutation/DeleteCoPassengerMutation";
import UpdateCoPassengerMutation from "../mutation/UpdateCoPassengerMutation";
import CreateChecklistNameMutation from "../mutation/CreateChecklistNameMutation";
import DeleteChecklistMutation from "../mutation/DeleteChecklistMutation";
import CreateCallRequest from "../mutation/CreateCallRequestMutation";
import RemovePromoCashMutation from "../mutation/RemovePromoCashMutation";
import ApplyPromoCodeMutation from "../mutation/ApplyPromoCashMutation";
import uberHookRedirectViewMutation from "../mutation/UberHookRedirectViewMutation";
import GetBookingDetailsByPnrAndNameMutation from "../mutation/GetBookingDetailsByPnrAndNameMutation";
import ApplyInsuranceMutation from "../mutation/ApplyInsuranceMutation";
import DeleteChecklistItemMutation from "../mutation/DeleteChecklistItemMutation";
import AddNewChecklistItemMutation from "../mutation/AddNewChecklistItemMutation";
import AddNewChecklistReminderMutation from "../mutation/AddNewChecklistReminderMutation";
import UpdateCheckListItemAndReminderMutation from "../mutation/UpdateCheckListItemAndReminderMutation";
import DeleteItemInChecklistMutation from "../mutation/DeleteItemInChecklistMutation";
import DeleteRemindersInChecklistMutation from "../mutation/DeleteRemindersInChecklistMutation";
import UpdateChecklistNameMutation from "../mutation/UpdateChecklistNameMutation";
// import React from "@types/react";


export const BookingCheckout = (variables, navigate) => {

    BookingCheckoutMutation(variables, (response) => {
        
        if (response.bookingCheckout !== null) {
            store.dispatch(setBookingDataAction(response.bookingCheckout));
            store.dispatch(setSelectedBookingIdAction(response.bookingCheckout.booking.id))
            store.dispatch(setUserDataAction(response.bookingCheckout.booking.user));
            if (response.bookingCheckout.booking.contact !== null) {
                store.dispatch(setContactDataAction(response.bookingCheckout.booking.contact));
            } else {
                store.dispatch(setContactDataAction({
                    email: "",
                    phoneNumber: ""
                }));
            }
            navigate('/booking-details', {replace: false});
            store.dispatch(setLoadingStateAction(false));
        }
    }, (err) => {
        // cogoToast.error(err, {position: 'top-center'})
        store.dispatch(setLoadingStateAction(false));
        cogoToast.error(<CustomToast type={"error"} message={err[0]?.message || err.toString()} heading={"Error"}/>, {position: "top-center"})
    })

}

export const BookingReschedule = (variables, navigate, isDesktopOrLaptop) => {

    BookingRescheduleMutation(variables, (response) => {
        if (response.bookingReschedule !== null) {
            store.dispatch(setBookingDataAction(response.bookingReschedule));
            store.dispatch(setSelectedBookingIdAction(response.bookingReschedule.booking.id))
            store.dispatch(setUserDataAction(response.bookingReschedule.booking.user));
            store.dispatch(setContactDataAction(response.bookingReschedule.booking.contact));
            if (isDesktopOrLaptop) {
                navigate('/booking-details', {replace: false});
            } else {
                navigate('/payment', {replace: false});
            }

        }
    }, (err) => {
        // cogoToast.error(err, {position: 'top-center'})
        cogoToast.error(<CustomToast type={"error"} message={err[0]?.message || err.toString()} heading={"Error"}/>, {position: "top-center"})
    })

}

export const BookingComplete = (variables, navigate) => {
    BookingCompleteMutation(variables, (response) => {
        if (response.bookingComplete !== null) {
            // store.dispatch(setContactDataAction(response.bookingComplete.contactDetail));
            clearOnCheckout()
            if (response.bookingComplete.isBookingCompleted) {
                navigate('/confirmation?' + variables.bookingId, {replace: false})
            } else {
                window.open(response.bookingComplete.payment.paymentUrl, "_self")
            }

            // navigate('/confirmation',{replace:false});

        }
    }, (err) => {
        store.dispatch(setLoadingStateAction(false));
        // cogoToast.error(err, {position: 'top-center'});
        cogoToast.error(<CustomToast type={"error"} message={err[0]?.message || err.toString()} heading={"Error"}/>, {position: "top-center"})
    })
}

export const BookingRescheduleComplete = (variables, navigate) => {
    BookingRescheduleCompleteMutation(variables, (response) => {
        
        if (response.completeRescheduleBooking !== null) {
            // store.dispatch(setContactDataAction(response.bookingComplete.contactDetail));
            clearOnCheckout()
            if(response.completeRescheduleBooking.isRescheduleCompleted){
                navigate('/confirmation/'+variables.bookingId,{replace:false});
            }else{
                window.open(response.completeRescheduleBooking.payment.paymentUrl, "_self")
            }

            // window.open(response.completeRescheduleBooking.payment.paymentUrl, "_self")
            // navigate('/confirmation',{replace:false});

        }
        else{
            store.dispatch(setLoadingStateAction(false));
        }
    }, (err) => {
        store.dispatch(setLoadingStateAction(false));
        // cogoToast.error(err, {position: 'top-center'});
        cogoToast.error(<CustomToast type={"error"} message={err[0]?.message || err.toString()} heading={"Error"}/>, {position: "top-center"})
    })
}

export const Signup = (variables,successCallback,errCallback) => {
    SignupMutation(variables, (response) => {
        if (response.mobileSignUpOrLogin !== null) {
            localStorage.setItem('signinORsignup', 'signup');
            localStorage.setItem('ReSendOTP', 'no');
            successCallback()}
    }, (err) => {
        errCallback()
        cogoToast.error(<CustomToast type={"error"} message={err[0]?.message || err.toString()} heading={"Error"}/>, {position: 'top-center'});
    })
}

export const Login = (variables,successCallback,errCallback) => {
    LoginMutation(variables, (response) => {
        if (response.mobileSignUpOrLogin !== null) {
            localStorage.setItem('signinORsignup', 'signin');
            localStorage.setItem('ReSendOTP', 'no');
            successCallback()
            cogoToast.success(<CustomToast type={"success"} message={"OTP sent successfully"}
                                           heading={"Success"}/>, {position: 'top-center'});
                                        
        }
    }, (err) => {
        errCallback()
        cogoToast.error(<CustomToast type={"error"} message={err[0]?.message || err.toString()} heading={"Error"}/>, {position: 'top-center'});
    })

}

export const VerifyOtp = (variables, isDesktopOrLaptop, successCallback) => {

    VerifyOtpMutation(variables, (response) => {
        if (response.verifyOtp.status !== "error") {
            // navigate('/',{replace:false});

            let signinORsignup = localStorage.getItem("signinORsignup")
            let ReSendOTP = localStorage.getItem("ReSendOTP")
            localStorage.setItem('userName', `${response.verifyOtp.user.username}`);
            localStorage.setItem('userEmail', `${response.verifyOtp.user.email}`);
            localStorage.setItem('userNumber', `${response.verifyOtp.user.mobileNumber}`);
            localStorage.setItem('userID', `${response.verifyOtp.user.id}`);
            
            hashAndEncode(`${response.verifyOtp.user.mobileNumber}`, "nuego").then((hashedData) => {
                     localStorage.setItem('moeID', `${hashedData}`);
                     window.Moengage.add_unique_user_id(`${hashedData}`)
                     ?.then(() => { return window.Moengage.add_first_name(`${response.verifyOtp.user.username}`); })
                     ?.then(() => { return window.Moengage.add_email(`${response.verifyOtp.user.email}`); })
                     ?.then(() => { return window.Moengage.add_mobile(`${response.verifyOtp.user.mobileNumber}`); }) 
                });
            verifyHaptikAndSignUp(response.verifyOtp.user.mobileNumber)
            
            if (signinORsignup === 'signin') {

                window.Moengage.track_event("sign_in", {
                    "account_id": `${localStorage.getItem("moeID")}`,
                    "email": `${response.verifyOtp.user.email}`,
                    "platform": `${isDesktopOrLaptop ? 'website' : 'mobile website'}`,
                    "fullname": `${response.verifyOtp.user.username}`,
                    "mobile_number": `${response.verifyOtp.user.mobileNumber}`,
                    "sign_in_origin": `${window.location.href}`,
                    "sign_in_success": "yes",
                    "otp_verfication_success": "yes",
                    "otp_resend_code": `${ReSendOTP}`,
                    "sign_up_channel": "otp"
                });
            } else {
                window.Moengage.track_event("signup", {
                    "account_id": `${localStorage.getItem("moeID")}`,
                    "email": `${response.verifyOtp.user.email}`,
                    "platform": `${isDesktopOrLaptop ? 'website' : 'mobile website'}`,
                    "fullname": `${response.verifyOtp.user.username}`,
                    "mobile_number": `${response.verifyOtp.user.mobileNumber}`,
                    "sign_up_success": `${window.location.href}`,
                    "sign_up_origin": "yes",
                    "otp_verfication_success": "yes",
                    "otp_resend_code": `${ReSendOTP}`,
                    "sign_up_channel": "otp"
                });
            }
            store.dispatch(setUserDataAction(response.verifyOtp.user));
            // setting it in local storage
            localStorage.setItem('userToken', response.verifyOtp.token);
            localStorage.setItem('refreshToken', response.verifyOtp.refreshToken)
            store.dispatch(setContactDataAction({
                email:response.verifyOtp.user.email,
                phoneNumber:response.verifyOtp.user.mobileNumber
            }));
            // setting in store
            store.dispatch(setTokenData({
                token: response.verifyOtp.token,
                refreshToken: response.verifyOtp.refreshToken
            }))
            store.dispatch(setAuthType(''));
            successCallback(response)
            // cogoToast.success("Successfully Login", {position: 'top-center'});
            cogoToast.success(<CustomToast type={"success"} message={"Successfully Login"}
                                           heading={"Success"}/>, {position: 'top-center'});
        } else {
            // cogoToast.error("Incorrect OTP", {position: 'top-center'});
            cogoToast.error(<CustomToast type={"error"} message={"Incorrect OTP"}
                                         heading={"Error"}/>, {position: 'top-center'});
        }
    }, (err) => {
        // cogoToast.error(err, {position: 'top-center'});
        cogoToast.error(<CustomToast type={"error"} message={err[0]?.message || err.toString()} heading={"Error"}/>, {position: 'top-center'});
    })
}

export const ResendOtp = (variables,successCallback) => {
    ResendOtpMutation(variables, (response) => {
        if (response.resendOtp !== null) {
            // navigate('/',{replace:false});
            localStorage.setItem('ReSendOTP', 'yes');
            successCallback(response.resendOtp)
            // cogoToast.success("OTP sent successfully", {position: 'top-center',hideAfter:5});
            cogoToast.success(<CustomToast type={"success"} message={"OTP Re-Sent Successfully"}
                                           heading={"Success"}/>, {position: 'top-center', hideAfter: 5});

        }
    }, (err) => {
       
        // cogoToast.error(err, {position: 'top-center'});
        cogoToast.error(<CustomToast type={"error"} message={err[0]?.message || err.toString()} heading={"Error"}/>, {position: 'top-center'});
    })
}

export const ReleaseSeat = (variables) => {
    ReleaseSeatsMutation(variables, (response) => {
        if (response.releaseSeats !== null) {
            // navigate('/',{replace:false});

        }
    })
}

export const cancelSinglePassenger = (variables, successCallback, errCallback) => {
    CancelBookingSinglePassengerMutation(variables, (response) => {
        if (response.cancelBooking !== null) {
            successCallback(response.cancelBooking)
        }
    }, (err) => {
        errCallback(err)
    })
}

export const PartialCancelBooking = (variables, successCallback, errCallback) => {
    CancelBookingMutation(variables, (response) => {
            if (response.confirmPartialBookingCancellation !== null) {
                // navigate('/',{replace:false});
                successCallback(response.confirmPartialBookingCancellation)
                // cogoToast.success("Booking cancelled successfully", {position: 'top-center'});
                cogoToast.success(<CustomToast type={"success"} message={"Booking cancelled successfully"}
                                               heading={"Success"}/>, {position: 'top-center'});
            }
        }, (err) => {
            // cogoToast.error(err, {position: 'top-center'});
            errCallback()
            cogoToast.error(<CustomToast type={"error"} message={err[0]?.message || err.toString()} heading={"Error"}/>, {position: 'top-center'});
        }
    )
}

export const ApplyCouponData = (variables, successCallback, errCallback) => {
    ApplyCouponMutation(variables, (response) => {
        if (response.applyDiscount !== null) {
            successCallback(response.applyDiscount)
            store.dispatch(setCouponMessageAction(response.applyDiscount.extraMessage));
            store.dispatch(setBookingDataAction(response.applyDiscount));
            store.dispatch(setSelectedBookingIdAction(response.applyDiscount.booking.id))
        } else {
            errCallback()
        }
    }, (err) => {
        errCallback(err)
    })
}

export const ModifyBookingData = (variables, successCallback, errCallback) => {
    ModifyBookingMutation(variables, (response) => {
        if (response.modifyBooking !== null) {
            successCallback(response.modifyBooking)

            cogoToast.success(<CustomToast message={"Modified Successfully!"}
                                           type={"success"}
                                           heading={"Success"}
            />, {position: 'top-center'})

            // TODO need to verify
            store.dispatch(setBookingDataAction(response.modifyBooking.booking));
            store.dispatch(setSelectedBookingIdAction(response.modifyBooking.booking.id))
        } else {
            errCallback()
        }
    }, (err) => {
        cogoToast.error(<CustomToast type={"error"} message={err[0]?.message || err.toString()} heading={"Error"}/>, {position: "top-center"})
        errCallback(err)
    })
}

export const DownloadMobileAppData = (variables, successCallback, errCallback) => {
    DownloadAppMutation(variables, (response) => {
        if (response.downloadMobileApp !== null) {
            successCallback(response.downloadMobileApp)
            cogoToast.success(<CustomToast type={"success"} message={response.downloadMobileApp.message}
                                           heading={"Success"}/>, {position: 'top-center'});
        } else {
            errCallback()
        }
    }, (err) => {
        errCallback(err)
    })
}

export const ApplyDefaultCouponData = (variable, successCallback, errCallback) => {

    ApplyDefaultCouponMutation(variable, (response) => {
        if (response.applyDefaultCoupon !== null) {
            successCallback(response.applyDefaultCoupon)
            if (response.applyDefaultCoupon.booking.coupon !== null) {
                cogoToast.success(<CustomToast message={response.applyDefaultCoupon.message}
                                               type={"success"}
                                               heading={"Success"}
                />, {position: 'top-center'})
            } else {
                // cogoToast.warn(<CustomToast message={response.applyDefaultCoupon.message}
                //                             type={"warn"}
                //                             heading={"Warning"}
                // />, {position: 'top-center', hideAfter: 3})
            }
            store.dispatch(setCouponMessageAction(response.applyDefaultCoupon?.extraMessage || ""));
            store.dispatch(setBookingDataAction({booking: response.applyDefaultCoupon.booking}));
            store.dispatch(setSelectedBookingIdAction(response.applyDefaultCoupon.booking.id))

        } else {
            errCallback()
        }
    }, (err) => {
        // errCallback(err)
        // cogoToast.warn(<CustomToast message={err[0]?.message || err.toString()}
        //                             type={"warn"}
        //                             heading={"Warning"}
        // />, {position: 'top-center'})
    })
}

export const RemoveDiscountData = (variables, successCallback, errCallback) => {

    RemoveDiscountMutation(variables, (response) => {
        if (response.removeDiscount) {
            successCallback(response)
            store.dispatch(setCouponMessageAction(""));
            store.dispatch(setBookingDataAction({booking: response.removeDiscount.booking}));
            cogoToast.success(<CustomToast message={response.removeDiscount.message}
                                           type={"success"}
                                           heading={"Success"}
            />, {position: 'top-center'})
        } else {
            errCallback({})
            cogoToast.warn(<CustomToast message={"Something went Wrong"}
                                        type={"warn"}
                                        heading={"Warning"}
            />, {position: 'top-center'})
        }
    }, (err) => {
        errCallback(err)
        cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                     type={"error"}
                                     heading={"Error"}
        />, {position: 'top-center'})
    })
}

export const ModifyProfilePicData = async (variables, successCallback, errCallback) => {
    let userId = variables.userId.toString()

    const formData = new FormData();
    formData.append('query', `mutation uploadProfilePicture {\nuploadProfilePicture(userId:"${userId}"){\nprofile{\nid\nprofilePic\nprofilePicUrl\n}\n}\n}`);
    formData.append('file', variables.file);
    const data = await fetch(api, {
        method: "POST",
        body: formData,
        headers: {
            'Authorization': `JWT ${localStorage.getItem('userToken')}`
        }
    });

    const response = await data.json();
    if (response.data.uploadProfilePicture !== null && response.data.uploadProfilePicture !== undefined) {
        successCallback(response.data.uploadProfilePicture)
    } else {
        errCallback(response?.errors[0]?.message)
    }

}

export const UpdateProfileData = (variables, successCallback, errCallback) => {
    UpdateProfileMutation(variables, (response) => {
        if (response.updateProfile !== null) {
            window.Moengage.update_unique_user_id(`${localStorage.getItem('moeID')}`)
            ?.then(() => { return window.Moengage.add_first_name(`${response.updateProfile.userDetail.user.username}`); })
            ?.then(() => { return window.Moengage.add_email(`${response.updateProfile.userDetail.user.email}`); })
            successCallback(response.updateProfile)
            cogoToast.success(<CustomToast message={"Profile Updated Successfully!"}
                                           type={"success"}
                                           heading={"Success"}
            />, {position: 'top-center'})
            store.dispatch(setProfileData(response.updateProfile.userDetail))
        } else {
            cogoToast.error(<CustomToast message={"Something went wrong"}
                                         type={"error"}
                                         heading={"Error"}
            />, {position: 'top-center'})
        }
    }, (err) => {
        errCallback(err)

        // cogoToast.error(<CustomToast message={err.message}
        //                              type={"error"}
        //                              heading={"Error"}
        // />, {position: 'top-center'})
    })
}

export const AddMoneyToWalletData = (variables, successCallback, errCallback) => {
    AddMoneyToWalletMutation(variables, (response) => {
        if (response.data.addMoneyToWallet !== null) {
            successCallback(response.data.addMoneyToWallet)
            // cogoToast.success(<CustomToast message={response.data.addMoneyToWallet.message}
            //                                type={"success"}
            //                                heading={"Success"}
            // />, {position: 'top-center'})
        } else {
            errCallback()
        }
    }, (err) => {
        errCallback(err)
        cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                     type={"warning"}
                                     heading={"Error"}
        />, {position: 'top-center'})
    })
}

export const ApplyWalletData = (variable, successCallback, errCallback) => {
    ApplyWalletMutation(variable, (response) => {
        if (response.applyWallet !== null) {
            successCallback(response.applyWallet)
            cogoToast.success(<CustomToast message={response.applyWallet.message}
                                           type={"success"}
                                           heading={"Success"}
            />, {position: 'top-center'})
            store.dispatch(setBookingDataAction({booking: response.applyWallet.bookingCheckout}));
            store.dispatch(setSelectedBookingIdAction(response.applyWallet.bookingCheckout.id))
        } else {
            errCallback()
        }
    }, (err) => {
        errCallback(err)
        cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                     type={"error"}
                                     heading={"Error"}
        />, {position: 'top-center'})
    })
}

export const RemoveWalletData = (variable, successCallback, errCallback) => {
    RemoveWalletFromBookingMutation(variable, (response) => {
        if (response.removeWalletMoneyFromBooking !== null) {
            successCallback(response.removeWalletMoneyFromBooking)
            cogoToast.success(<CustomToast message={response.removeWalletMoneyFromBooking.message}
                                           type={"success"}
                                           heading={"Success"}
            />, {position: 'top-center'})
            store.dispatch(setBookingDataAction({booking: response.removeWalletMoneyFromBooking.bookingCheckout}));
            store.dispatch(setSelectedBookingIdAction(response.removeWalletMoneyFromBooking.bookingCheckout.id))
        } else {
            errCallback()
        }
    }, (err) => {
        errCallback(err)
        cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                     type={"error"}
                                     heading={"Error"}
        />, {position: 'top-center'})
    })
}

export const ApplyJobData = async (variables, successCallback, errCallback) => {

    const formData = new FormData();
    formData.append('query', `mutation applyForJobMutation {\napplyForJob(name:"${variables.name}",
	email:"${variables.email}",
	mobileNumber:"${variables.mobileNumber}",
	roleApplyingFor:"${variables.roleApplyingFor}",
	coverLetter:"${variables.coverLetter}", 
	countryCode:"${variables.countryCode}"
	){\nok\n}\n}`);
    formData.append('resume', variables.resume);
    try {
        const data = await fetch(api, {
            method: "POST",
            body: formData,

        });

        const response = await data.json();

        if (response.data.applyForJob !== null && response.data.applyForJob !== undefined) {
            successCallback(response.data.applyForJob)
        } else {
            errCallback(response.errors[0].message)
        }
    } catch (err) {
        errCallback(err.message)
    }

}

export const PartnerWithUsData = async (variables, successCallback, errCallback) => {

    const formData = new FormData();
    formData.append('query', `mutation joinPartnerProgramMutation {\njoinPartnerProgram(
	name:"${variables.name}",
	organizationName:"${variables.organizationName}",
	websiteUrl:"${variables.website}",
	nationality:"${variables.Nationality}",
	email:"${variables.email}",
	mobileNumber:"${variables.mobileNumber}",
	countryCode:"${variables.countryCode}",
	){\nstatus\n}\n}`);
    formData.append('document', variables.document);

    try {
        const data = await fetch(api, {
            method: "POST",
            body: formData,

        });

        const response = await data.json();

        if (response.data.joinPartnerProgram !== null && response.data.joinPartnerProgram !== undefined) {
            successCallback(response.data.joinPartnerProgram)
        } else {
            errCallback(response.errors[0].message)
        }
    } catch (e) {
        errCallback(e.message)
    }

}

export const ReviewFeedBackData = async (variables, successCallback, errCallback) => {

    const formData = new FormData();
    formData.append('query', `mutation createReviewMutation {\ncreateReview(reviewData:{\n
	travelExperience:${variables.travelExperience},
	hospitality:${variables.hospitality},
	cleanliness:${variables.cleanliness},
	content:"${variables.content}",
	bookingId:${variables.bookingId},
	}\n
	){\nstatus\n}\n}`);
    formData.append('image', variables.image);

    try {
        const data = await fetch(api, {
            method: "POST",
            body: formData,
            headers: {
                'Authorization': `JWT ${localStorage.getItem('userToken')}`
            }
        });

        const response = await data.json();

        if (response.data.createReview !== null && response.data.createReview !== undefined) {
            successCallback(response.data.createReview)
        } else {
            errCallback(response.errors[0].message)
        }
    } catch (e) {
        errCallback(e.message)
    }

}

export const CreateCoPassengerData = (variables, successCallback, errCallback) => {
    CreateCoPassengerMutation(
        variables, (response) => {
            if (response.createCoPassenger !== null) {
                successCallback(response.createCoPassenger)
                if(response.createCoPassenger.message.includes('success')){
                    cogoToast.success(<CustomToast message={response.createCoPassenger.message}
                                                   type={"success"}
                                                   heading={"Success"}
                    />, {position: 'top-center'})
                }else{
                    cogoToast.error(<CustomToast message={response.createCoPassenger.message}
                                                 type={"error"}
                                                 heading={"Error"}
                    />, {position: 'top-center'})
                }


            } else {
                errCallback()
            }
        }, (err) => {
            errCallback(err)
            cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                         type={"error"}
                                         heading={"Error"}
            />, {position: 'top-center'})
        })
}

export const DeleteCoPassengerData = (variables, successCallback, errCallback) => {
    DeleteCoPassengerMutation(
        variables, (response) => {
            if (response.deleteCoPassenger !== null) {
                successCallback(response.deleteCoPassenger)
                cogoToast.success(<CustomToast message={"Successfully removed"}
                                               type={"success"}
                                               heading={"Success"}
                />, {position: 'top-center'})
            } else {
                errCallback()
            }
        }, (err) => {
            errCallback(err)
            cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                         type={"error"}
                                         heading={"Error"}
            />, {position: 'top-center'})
        })
}

export const UpdateCoPassengerData = (variables, successCallback, errCallback) => {
    UpdateCoPassengerMutation(
        variables, (response) => {
            if (response.updateCoPassenger !== null) {
                successCallback(response.updateCoPassenger)
                cogoToast.success(<CustomToast message={"Successfully updated"}
                                               type={"success"}
                                               heading={"Success"}
                />, {position: 'top-center'})
            } else {
                errCallback()
            }
        }, (err) => {
            errCallback(err)
            cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                         type={"error"}
                                         heading={"Error"}
            />, {position: 'top-center'})
        })
}

export const CreateChecklistData = (variables, successCallback, errCallback) => {
    CreateChecklistNameMutation(variables, (response) => {
        if (response.createCheckList !== null) {
            successCallback(response.createCheckList)
            cogoToast.success(<CustomToast message={"Successfully created"}
                                           type={"success"}
                                           heading={"Success"}
            />, {position: 'top-center'})
        } else {
            errCallback()
        }
    }, (err) => {
        errCallback(err)
        cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                     type={"error"}
                                     heading={"Error"}
        />, {position: 'top-center'})
    })
}

export const DeleteChecklistData = (variables, successCallback, errCallback) => {
    DeleteChecklistMutation(variables, (response) => {
        if (response.deleteCheckList !== null) {
            successCallback(response.deleteCheckList)
            cogoToast.success(<CustomToast message={"Successfully Removed"}
                                           type={"success"}
                                           heading={"Success"}
            />, {position: 'top-center'})
        } else {
            errCallback()
        }
    }, (err) => {
        errCallback(err)
        cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                     type={"error"}
                                     heading={"Error"}
        />, {position: 'top-center'})
    })
}

export const CallReuestData = (variable, successCallback, errCallback) => {
    CreateCallRequest(variable, (response) => {
        if (response.removeWalletMoneyFromBooking !== null) {
            successCallback(response.removeWalletMoneyFromBooking)
            cogoToast.success(<CustomToast message={response.removeWalletMoneyFromBooking.message}
                                           type={"success"}
                                           heading={"Success"}
            />, {position: 'top-center'})
            // store.dispatch(setBookingDataAction({booking: response.removeWalletMoneyFromBooking.bookingCheckout}));
            // store.dispatch(setSelectedBookingIdAction(response.removeWalletMoneyFromBooking.bookingCheckout.id))
        } else {
            errCallback()
        }
    }, (err) => {
        errCallback(err)
        cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                     type={"error"}
                                     heading={"Error"}
        />, {position: 'top-center'})
    })
}

export const RemovePromoCash = (variable, successCallback, errCallback) => {
    RemovePromoCashMutation(variable, (response) => {

        if (response.removePromoCash !== null) {
            successCallback(response.removePromoCash)
            cogoToast.success(<CustomToast message={response.removePromoCash.message}
                                           type={"success"}
                                           heading={"Success"}
            />, {position: 'top-center'})
            store.dispatch(setBookingDataAction({booking: response.removePromoCash.bookingCheckout}));
        } else {
            errCallback()
        }
    }, (err) => {
        errCallback(err)
        cogoToast.error(<CustomToast message={err[0].message}
                                     type={"error"}
                                     heading={"Error"}
        />, {position: 'top-center'})
    })
}

export const ApplyPromoCash = (variable, successCallback, errCallback) => {
    ApplyPromoCodeMutation(variable, (response) => {

        if (response.applyPromoCash !== null) {
            successCallback(response.applyPromoCash)
            cogoToast.success(<CustomToast message={response.applyPromoCash.message}
                                           type={"success"}
                                           heading={"Success"}
            />, {position: 'top-center'})
            store.dispatch(setBookingDataAction({booking: response.applyPromoCash.bookingCheckout}));
        } else {
            errCallback()
        }
    }, (err) => {
        errCallback(err)
        cogoToast.error(<CustomToast message={err[0].message}
                                     type={"error"}
                                     heading={"Error"}
        />, {position: 'top-center'})
    })
}


export const UberHookRedirection = (variables) => {
    uberHookRedirectViewMutation(variables, (response) => {
        if (response.uberHookRedirectView !== null) {
            cogoToast.success(<CustomToast message={""}
                                           type={"success"}
                                           heading={"Success"}
            />, {position: 'top-center'})
        }
    }, (err) => {
        cogoToast.error(<CustomToast type={"error"} message={err[0]?.message || err.toString()} heading={"Error"}/>, {position: 'top-center'});
    })
}

export const GetBookingDetailsByPnrAndNameData = (variable, successCallback, errCallback) => {
    GetBookingDetailsByPnrAndNameMutation(variable, (response) => {
        if (response.getBookingDetailsByPnrAndName.booking !== null) {
            successCallback(response.getBookingDetailsByPnrAndName.booking)
            cogoToast.success(<CustomToast message={response.getBookingDetailsByPnrAndName.message}
                                           type={"success"}
                                           heading={"Success"}
            />, {position: 'top-center'})
            store.dispatch(postBookingDataAction(response.getBookingDetailsByPnrAndName.booking));
        } else {
            errCallback()
        }
    }, (err) => {
        errCallback(err)
        store.dispatch(postBookingDataAction({}));
    })
}


export const ApplyInsurance = (variables, successCallback, errCallback) => {
    ApplyInsuranceMutation(variables, (response) => {
        
        if (response.applyInsurance !== null) {
            successCallback(response.message)
            store.dispatch(setBookingDataAction({
                booking:{...store.getState().BookingReducer.setBookingData?.booking,
                totalPayable:response?.applyInsurance?.booking.totalPayable,
                getTotalPaidAmount:response?.applyInsurance?.booking.getTotalPaidAmount
            }
            }));
        } else {
            errCallback()
        }
    }, (err) => {
        errCallback(err)
    })
}

export const deleteChecklistItem = (variable, successCallback, errCallback) => {
    DeleteChecklistItemMutation(variable, (response) => {
            if (response.deleteCheckListItem !== null) {
                successCallback(response.deleteCheckListItem)
                cogoToast.success(<CustomToast message={response.deleteCheckListItem.message}
                                                type={"success"}
                                                heading={"Success"}
                />, {position: 'top-center'})
            } else {
                errCallback()
            }
        }
        , (err) => {
            errCallback(err)
            cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                            type={"error"}
                                            heading={"Error"}
            />, {position: 'top-center'})
        })
    }
export const AddNewCheckListItem=(variables,successCallback,errCallback)=>{
    AddNewChecklistItemMutation(variables,(response)=>{
        if(response.createCheckListItemAndReminder!==null){
            successCallback(response.createCheckListItemAndReminder)
            cogoToast.success(<CustomToast message={response.createCheckListItemAndReminder.message}
                                            type={"success"}
                                            heading={"Success"}
            />, {position: 'top-center'})
        }else{
            errCallback()
        }
    }
    ,(err)=>{
        errCallback(err)
        cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                        type={"error"}
                                        heading={"Error"}
        />, {position: 'top-center'})
    })
}

export const AddNewCheckListReminder=(variables,successCallback,errCallback)=>{
    AddNewChecklistReminderMutation(variables,(response)=>{
        if(response.createCheckListItemAndReminder!==null){
            successCallback(response.createCheckListItemAndReminder)
            cogoToast.success(<CustomToast message={response.createCheckListItemAndReminder.message}
                                            type={"success"}
                                            heading={"Success"}
            />, {position: 'top-center'})
        }else{
            errCallback()
        }
    }
    ,(err)=>{
        errCallback(err)
        cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                        type={"error"}
                                        heading={"Error"}
        />, {position: 'top-center'})
    })
}

export const UpdateChecklistItemAndReminder=(variables,successCallback,errCallback)=>{
    UpdateCheckListItemAndReminderMutation(variables,(response)=>{
        if(response.updateCheckListItemAndReminder!==null){
            successCallback(response.updateCheckListItemAndReminder)
            cogoToast.success(<CustomToast message={response.updateCheckListItemAndReminder.message}
                                            type={"success"}
                                            heading={"Success"}
            />, {position: 'top-center'})
        }else{
            errCallback()
        }
    }
    ,(err)=>{
        errCallback(err)
        cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                        type={"error"}
                                        heading={"Error"}
        />, {position: 'top-center'})
    })
}

export const DeleteItemInChecklist=(variables,successCallback,errCallback)=>{
    DeleteItemInChecklistMutation(variables,(response)=>{
        if(response.deleteCheckListItems!==null){
            successCallback(response.deleteCheckListItems)
            cogoToast.success(<CustomToast message={response.deleteCheckListItems.message}
                                            type={"success"}
                                            heading={"Success"}
            />, {position: 'top-center'})
        }else{
            errCallback()
        }
    }
    ,(err)=>{
        errCallback(err)
        cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                        type={"error"}
                                        heading={"Error"}
        />, {position: 'top-center'})
    })
}

export const DeleteRemindersInChecklist = (variables, successCallback, errCallback) => {
    DeleteRemindersInChecklistMutation(variables, (response) => {
        if (response.deleteReminders !== null) {
            successCallback(response.deleteReminders)
            cogoToast.success(<CustomToast message={response.deleteReminders.message}
                                           type={"success"}
                                           heading={"Success"}
            />, {position: 'top-center'})
        } else {
            errCallback()
        }
    }, (err) => {
        errCallback(err)
        cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                     type={"error"}
                                     heading={"Error"}
        />, {position: 'top-center'})
    })
}

export const updateChecklistName=(variables,successCallback,errCallback)=>{
    UpdateChecklistNameMutation(variables,(response)=>{
        if(response.updateCheckList!==null){
            successCallback(response.updateCheckListName)
            cogoToast.success(<CustomToast message={response.updateCheckList.message}
                                            type={"success"}
                                            heading={"Success"}
            />, {position: 'top-center'})
        }else{
            errCallback()
        }
    }
    ,(err)=>{
        errCallback(err)
        cogoToast.error(<CustomToast message={err[0]?.message || err.toString()}
                                        type={"error"}
                                        heading={"Error"}
        />, {position: 'top-center'})
    })
}
