import React, { useEffect, useState } from "react";
import "../TicketDetailsPage.scss";
import { useMediaQuery } from "react-responsive";

// assets
const processedIllustration = process.env.REACT_APP_CDN_LINK + `assets/images/processedIllustration.png`
const pendingIllustration = process.env.REACT_APP_CDN_LINK + `assets/images/pendingIllustration.png`

const RefundStatusTicketDetail = ({ data }) => {
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
  const [isProcessed, setIsProcessed] = useState(false);

  useEffect(() => {
    if (data) {
      if (data.isRefunded) {
        setIsProcessed(true);
      } else {
        setIsProcessed(false);
      }
    }
    return () => {};
  }, []);

  let pageConstants;
  let ticketStatusDetails;
  if (isDesktopOrLaptop) {
    pageConstants = {
      heading_class: "ubuntu-700w-24s-36h mb-4",
      cardHeading: "open-600w-16s-24h mb-0 black-1E1E1E-color",
      cardSubHeading: "open-400w-14s-22h mb-0 grey-2-78787d-color",
      key_class: "open-400w-14s-22h mb-0 grey-2-78787d-color",
      value_class: "open-600w-14s-22h mb-0 black-1E1E1E-color",
    };
  } else {
    pageConstants = {
      heading_class: "ubuntu-700w-18s-28h mb-2",
      cardHeading: "open-600w-16s-24h mb-0 black-1E1E1E-color",
      cardSubHeading: "open-400w-14s-22h mb-0 grey-2-78787d-color",
      key_class: "open-400w-14s-22h mb-0 grey-2-78787d-color",
      value_class: "open-600w-14s-22h mb-0 black-1E1E1E-color",
    };
  }

  if (isProcessed) {
    ticketStatusDetails = {
      illustration: processedIllustration,
      heading: "Your refund has been processed",
      subHeading: "The refund is credited to your original mode of payment",
    };
  } else {
    ticketStatusDetails = {
      illustration: pendingIllustration,
      heading: "Refund request registered",
      subHeading: "Your refund, if applicable, will be processed within 5-7 working days",
    };
  }

  return (
    <div className={"refund-status-ticket-detail"}>
      <p className={pageConstants.heading_class}>Refund Status</p>
      <div className={"card d-flex flex-row align-items-center gap-12"}>
        <div>
          <img
            src={ticketStatusDetails.illustration}
            alt={"illustration"}
            className={"illustration"}
          />
        </div>
        <div>
          <p className={pageConstants.cardHeading}>
            {ticketStatusDetails.heading}
          </p>
          <p className={pageConstants.cardSubHeading}>
            {ticketStatusDetails.subHeading}
          </p>
        </div>
      </div>

      <div
        className={
          "status-breakdown d-flex flex-row justify-content-between align-items-center w-100 py-4"
        }
      >
        <div className={pageConstants.key_class}>
          <p>Transaction ID</p>
          <p>Payment Gateway</p>
          <p>Payment Date</p>
          <p>Total Fare</p>
          <p>Cancellation Charges</p>
          <p>Refund Amount</p>
        </div>
        <div className={pageConstants.value_class}>
          <p>
            {data.cancellationTransactionId
              ? data.cancellationTransactionId
              : "-"}
          </p>
          {/* <p>{data.paymentType ? data.paymentType : "-"}</p> */}
          <p>{data?.paymentType}</p>

          {/*<p>{convertDateWithMonth(data.dateOfJourney)} {(data.dateOfJourney).getTime}</p>*/}
          <p>
            {/* {convertDateWithMonth(data.dateOfJourney)},{" "}
            {convertToIST(data.dateOfJourney)} */}
            {data.initialPaymentDate + "," + data.initialPaymentTime}
          </p>
          <p>
            {data.getTotalPaidAmount ? "₹ " + data.getTotalPaidAmount : "-"}
          </p>
          <p>
            {data.cancellationCharges ? "₹ " + data.cancellationCharges : "-"}
          </p>
          <p>
            {data.totalRefundedAmount ? "₹ " + data.totalRefundedAmount : "-"}
          </p>
        </div>
      </div>
    </div>
  );
};

export default RefundStatusTicketDetail;
