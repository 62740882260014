import React, {useRef, useState} from 'react'
import {useMediaQuery} from "react-responsive";
import {MDBModal, MDBModalBody} from "mdbreact";
import ToggleButtonElement from "../../ToggleButtonElement/ToggleButtonElement";
import uuid from "react-uuid";

//icons
import closeIcon from "../../../assets/icons/cross-gray.svg"
import checkedIcon from "../../../assets/icons/checked-green.svg"
import uncheckedIcon from "../../../assets/icons/unchecked.svg"
import addButtonGreenCircle from "../../../assets/icons/add-button-green-circle.svg"
import deleteIcon from "../../../assets/icons/delete-icon.svg"
import editIcon from "../../../assets/icons/edit-icon-black.svg"
import addIcon from  '../../../assets/icons/add-icon-teal.svg'
import { AddNewCheckListItem, AddNewCheckListReminder, DeleteItemInChecklist, DeleteRemindersInChecklist, UpdateChecklistItemAndReminder, updateChecklistName } from '../../../services/api/DataUpdating';
import { useEffect } from 'react';
import { addDaysToDate, convertDateToISO, formatDateToDDMMYYYY, reconvertionDate } from '../../../utils/utils';
import Calendar from 'react-calendar';


const ChecklistDataWidget = ({
                                 dataModal,
                                 setDataModal,
                                 componentData,
                                 selectedChecklistData
                             }) => {
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});

    const [checklistData, setChecklistData] = useState([])

    
    const [editChecklistName, setEditChecklistName] = useState(false);
    const [viewCalendar, setViewCalendar] = useState("");
    const [tempChecklistItemName, setTempChecklistItemName] = useState("");
    const [initChecklistEmpty, setInitChecklistEmpty] = useState(true);

    const ref = useRef();

    useEffect(() => {
        setChecklistData(selectedChecklistData)
        if(selectedChecklistData.checklistitemSet.length > 0){
            setInitChecklistEmpty(false)
        }
    }, [selectedChecklistData])

    useEffect(() => {
        const checkIfClickedOutside = (e) => {
            // If the menu is open and the clicked target is not within the menu,
            // then close the menu
            if (viewCalendar && ref.current && !ref.current.contains(e.target)) {
                setViewCalendar(false);
            }
        };

        document.addEventListener("mousedown", checkIfClickedOutside);

        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", checkIfClickedOutside);
        };
    }, [viewCalendar]);


    const onChangeHandler=(value,field)=>{
        
        if(field === "name"){
            // e.preventDefault()
            setChecklistData({
                ...checklistData,
                [field]:value
            })    
            setTimeout(() => {
                if (document.querySelector("#checklist-editName-input")) {
                    document.querySelector("#checklist-editName-input").focus()
                }
            }, 1)
        } else{
            setChecklistData({
                ...checklistData,
                [field]:value
            })
        }
    }
    

    const addChecklistItemHandler = () => {
        let temp = [...checklistData.checklistitemSet]
        // check if name is already present checklistItem_local
        let isPresent = false

        temp.length > 0 && temp.forEach((item) => {
            if (item.node.name === tempChecklistItemName) {
                isPresent = true
            }
        })
        if (!isPresent) {
            // temp.push({node:{
            //     id: tempChecklistItemName + "_" + Math.random(),
            //     itemName: tempChecklistItemName,
            //     isChecked: false,
            // }})
            
            AddNewCheckListItem({
                checkListId:checklistData.id,
                checkListItems:[{
                    name:tempChecklistItemName,
                    isChecked:false
                }],
                isAadhaar:checklistData.isAadhaar,
                isPrescription:checklistData.isPrescription,
            },(response)=>{
                setChecklistData({
                    ...checklistData,
                    checklistitemSet:response.checkList.checklistitemSet.edges
                })
            },(err)=>{
                console.log(err)
            })
          
            setTempChecklistItemName("")
        }

    }

    const onChangeChecklistItemEditHandler = (e, id) => {
        e.preventDefault()
        setTempChecklistItemName(e.target.value)

        setTimeout(() => {
            if (document.querySelector(id)) {
                document.querySelector(id).focus()
            }
        }, 5)

    }

    const removeItemHandler = (id) => {
        // remove item from checklistItem_local array using id
        let temp = [...checklistData.checklistitemSet]
        let index = temp.findIndex((item) => item.node.id === id)
        temp.splice(index, 1)
        DeleteItemInChecklist({ids:[id]},(response)=>{
            console.log(response)
            setChecklistData({
                ...checklistData,
                checklistitemSet: temp
            })
        }
        ,(err)=>{
            console.log(err)
        })
        
    }

    const updateChecklistNameHandler = () => {
        setEditChecklistName(false)
        updateChecklistName({id:checklistData.id,name:checklistData.name},(response)=>{
            console.log(response)
        },(err)=>{
            console.log(err)
        })
    }

    const checkItemHandler = (id) => {
        // set isChecked to true for the item with id
        let temp = [...checklistData.checklistitemSet]
        let index = temp.findIndex((item) => item.node.id === id)
        console.log(temp[index].node)
        temp[index] = { node: {...
            temp[index].node,
            isChecked: !temp[index].node.isChecked
        }
        }
        // temp[index].node.isChecked = !temp[index].node.isChecked
        setChecklistData({
            ...checklistData,
            checklistitemSet:temp
        })
    }

    const deleteReminder = (id) => {
        let temp = [...checklistData.checklistreminderSet]
        let index = temp.findIndex((item) => item.node.id === id)
        index !== -1 && temp.splice(index, 1)
        DeleteRemindersInChecklist({ids:[id]},(response)=>{
            console.log(response)
        setChecklistData({
            ...checklistData,
            checklistreminderSet: temp
        })
        }   
        ,(err)=>{
            console.log(err)
        })
    }

    const addChecklistReminderHandler = () => {

        AddNewCheckListReminder({
            checkListId:checklistData.id,
            isAadhaar:checklistData.isAadhaar,
            isPrescription:checklistData.isPrescription,
            checkListReminder:[{
                date:formatDateToDDMMYYYY(new Date()),
                time:'12:00 AM'
            }]
        },(response)=>{            
            setChecklistData({
                ...checklistData,
                checklistreminderSet:response.checkList.checklistreminderSet.edges
            })
        }
        ,(err)=>{
            console.log(err)
        })
    }

    const changeDate=(value,id)=>{
        let temp = [...checklistData.checklistreminderSet]
        let index = temp.findIndex((item) => item.node.id === id)
        temp[index] = { node: {...
            temp[index].node,
            reminderDate: formatDateToDDMMYYYY(value)
         } };
        setChecklistData({
            ...checklistData,
            checklistreminderSet:temp
        })
    }

    const changeTime=(value,id,field)=>{
        let temp = [...checklistData.checklistreminderSet]
        let index = temp.findIndex((item) => item.node.id === id)
        let hh = temp[index].node.reminderTime.split(":")[0]
        let mm = temp[index].node.reminderTime.split(":")[1].split(" ")[0]
        let ampm = temp[index].node.reminderTime.split(" ")[1]
        temp[index] = { node: {...
            temp[index].node,
            reminderTime: field === "hh" ? value+":"+mm+" "+ampm : field === "mm" ? hh+":"+value+" "+ampm : hh+":"+mm+" "+value
         } };
        setChecklistData({
            ...checklistData,
            checklistreminderSet:temp
        })
    }
    // section

    const ChecklistDataContent = () => {
        console.log(checklistData)
        return <div className={'data-checklist-content'}>
            {isDesktopOrLaptop && <img src={closeIcon} alt={'close-icon'} className={'close-icon'}
                                       onClick={() => setDataModal(false)}/>}
            <div className={'margin-bottom-32 px-3 px-lg-0'}>
                {
                    editChecklistName ?
                        <div className={'common-input-wrap w-lg-50 d-flex gap-12 align-items-center'}>
                            <input className={'checklist-name-input w-100'}
                                   id={'checklist-editName-input'}
                                   value={checklistData.name}
                                   onBlur={updateChecklistNameHandler}
                                   onChange={(e) => onChangeHandler(e.target.value, "name")}/>
                            <img src={closeIcon} alt={'close-icon'} className={' icon-24'}
                                 onClick={() => setEditChecklistName(false)}/>
                        </div>

                        :
                        <div className={'d-flex gap-12 align-items-center  '}
                             onClick={() => setEditChecklistName(true)}>
                            <p className={"ubuntu-700w-24s-32h mb-0 " +
                                "black-1E1E26-color"}>{checklistData.name}</p>
                            <img src={editIcon} alt={'edit-icon'} className={'edit-icon icon-24'}
                            />
                        </div>

                }
            </div>

            <div>
                {/* recommended */}
                <div className={'px-3 px-lg-0'}>
                    <p className={componentData.subTitle_modal_class}>Recommended</p>
                    <div className=" mb-3">
                        <div className={'d-flex gap-12'} onClick={() => onChangeHandler(!checklistData.isAadhaar,"isAadhaar")}>
                            <img src={checklistData.isAadhaar ? checkedIcon : uncheckedIcon} alt={'aadhaar'}
                                 className={componentData.icon_size}/>
                            <p className={componentData.secondary_text_class}>Aadhar Card</p>
                        </div>

                        <div className={'d-flex gap-12'} onClick={() =>  onChangeHandler(!checklistData.isPrescription,"isPrescription")}>
                            <img src={checklistData.isPrescription ? checkedIcon : uncheckedIcon}
                                 alt={'prescription'}
                                 className={componentData.icon_size}/>
                            <p className={componentData.secondary_text_class}>Prescription &
                                Medications</p>
                        </div>
                    </div>
                </div>

                <hr className={'horizontal-divider'} style={{height: "6px"}}/>

                {/* your list */}

                <div className={'px-3 px-lg-0'}>

                    {
                        checklistData.checklistitemSet?.length > 0 &&
                        <p className={componentData.subTitle_modal_class}>Your list</p>

                    }
                    {
                        initChecklistEmpty ?
                            <div className={"add-list-box"} onClick={() => setInitChecklistEmpty(false)}>
                                <p className={componentData.addList_class}>You can add more items to your travel
                                    list</p>
                                <img src={addButtonGreenCircle} alt={'add'} className={componentData.icon_size}/>
                            </div>
                            :
                            <div className={""}>
                                {
                                    checklistData.checklistitemSet.map((item, index) => {
                                        return <div className={'d-flex align-items-center justify-content-between'}
                                                    key={uuid()}>
                                            <div className={'d-flex align-items-center gap-12'}>
                                                <img src={item.node.isChecked ? checkedIcon : uncheckedIcon}
                                                     alt={'checklist-item'}
                                                     onClick={() => checkItemHandler(item.node.id)}
                                                     className={componentData.icon_size}/>
                                                <p className={componentData.secondary_text_class}>{item.node.itemName}</p>
                                            </div>
                                            <img src={deleteIcon} alt={'delete-icon'} className={componentData.icon_size}
                                                 onClick={() => removeItemHandler(item.node.id)}/>
                                        </div>
                                    })
                                }
                                <div className={'d-flex align-items-center gap-12 my-3'}>
                                    {/* <img src={uncheckedIcon} alt={'checklist-item'}
                                         className={componentData.icon_size}/> */}
                                    <div
                                        className={'common-input-wrap w-lg-50 d-flex justify-content-between align-items-center gap-24'}>
                                        <input className={'checklist-name-input '}
                                               id={'checklist-addItem-input'}
                                               placeholder={'Add item'}
                                               onKeyPress={(e) => {
                                                   if (e.key === "Enter") {
                                                       addChecklistItemHandler()
                                                   }
                                               }}
                                               value={tempChecklistItemName}
                                               onChange={(e) => onChangeChecklistItemEditHandler(e, "checklist-addItem-input")}
                                        />
                                        <img src={addButtonGreenCircle} alt={'tick-icon'}
                                             className={componentData.icon_size}
                                             onClick={() => addChecklistItemHandler()}/>
                                    </div>
                                </div>
                            </div>
                    }
                </div>
                <hr className={'horizontal-divider'} style={{height: "6px"}}/>
                <div className={'px-3 px-lg-0'}>
                    {!checklistData.checklistreminderSet?.length > 0 ? 
                    <>
                        <div className={'d-flex align-items-center justify-content-between  '}>
                            <p className={componentData.subTitle_modal_class}>Reminder</p>
                            <ToggleButtonElement
                                toggleButton={checklistData.checklistreminderSet?.length > 0}
                                setToggleButton={()=>{
                                    onChangeHandler(true,"isReminder")
                                }}
                            />
                        </div>
                        <p className={componentData.secondary_text_class + " w-50"}>
                            You will be notified via this reminder an hour before your trip
                        </p>
                    </>
                    :
                    <div className='reminder-list'>
                        {
                            checklistData.checklistreminderSet.length > 0 && checklistData.checklistreminderSet.map((item, index) => {
                            return <div className='reminder-items' key={item.node.id}>
                                    <div className='d-flex justify-content-between align-items-center gap-3'>
                                        <p className={componentData.subTitle_modal_class}>Reminder {index+1}</p>
                                        <img className='icon-24' src={deleteIcon} alt='delete-icon' onClick={()=>deleteReminder(item.node.id)}/>
                                    </div>
                                    <div className='d-flex align-items-center gap-3'>
                                        <div className='date-wrapper w-100'>
                                            <div className='calendar-input position-relative'>
                                                <label className={componentData.secondary_text_class}>Date</label>
                                                <div className='common-input-wrap w-lg-50 d-flex justify-content-between align-items-center gap-24'>
                                                    {/* <input type='date' className="border-less-input w-100"
                                                    // readOnly={true}
                                                    onChange={(e) => {
                                                        changeDate(e.target.value, item.node.id)
                                                    }}
                                                    value={item.node.reminderDate}
                                                    /> */}
                                                    <input
                                                        type={"input"}
                                                        // value={convertDateWithMonth(calendarValue)}
                                                        value={item.node.reminderDate.includes("/")?convertDateToISO(item.node.reminderDate):item.node.reminderDate}
                                                        readOnly={true}
                                                        onClick={() => {
                                                            setViewCalendar(item.node.id);
                                                        }}
                                                        className={"border-less-input calender"}
                                                    />
                                                </div>
                                                
                                                {viewCalendar === item.node.id && (
                                                    <div className={"calendar-wrapper"}>
                                                        <Calendar
                                                            onChange={(e)=>{
                                                                changeDate(e, item.node.id)
                                                            }}
                                                            // defaultValue={convertToDateFormat(calendarValue)}
                                                            // value={convertToDateFormat(calendarValue)}
                                                            value={item.node.reminderDate.includes("/")?convertDateToISO(item.node.reminderDate):item.node.reminderDate}
                                                            minDate={new Date()}
                                                            inputRef={ref}
                                                            maxDate={addDaysToDate(new Date(), 30)}
                                                            onClickDay={(e) => {
                                                                // cogoToast.success(<CustomToast type={"success"} type={"loading"}
                                                                //                                message={"Checking Delivery date availablity"}/>, {position: "top-center"})
                                                                setTimeout(() => {
                                                                    // deliverySlotChecker(formik, convertDateReverse(e))
                                                                    setViewCalendar(false);

                                                                }, 1000);
                                                            }}
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                            {/* calendar to be here */}
                                        </div>
                                        <div className='time-wrapper w-100'>
                                            <div className='time-input'>
                                                <label>Time</label>
                                                <div className='common-input-wrap w-lg-50 d-flex justify-content-between align-items-center gap-2'>
                                                    <div className='d-flex w-100'>
                                                <select  className="border-less-input w-100 text-center"
                                                    // readOnly={true}
                                                    
                                                    onChange={(e) => {
                                                        changeTime(e.target.value,item.node.id,"hh")
                                                    }}
                                                    value={item.node.reminderTime.split(":")[0] }
                                                    // value={convertAMPMTo24hr(item.node.reminderTime) }
                                                     >
                                                      {
                                                            Array.from({length: 12}, (_, i) => {
                                                                let value = i.toString().length <=1?("0"+i).toString():i.toString()
                                                                return <option key={i} value={value} className="open-400w-14s-22h grey-2-78787d-color mb-0">{value}</option>
                                                            })
                                                        }
                                                     </select>
                                                     :
                                                    <select className="border-less-input w-100 text-center"
                                                    // readOnly={true}
                                                    value={item.node.reminderTime.split(":")[1].split(" ")[0]}
                                                    onChange={(e) => {
                                                        changeTime(e.target.value,item.node.id,"mm")
                                                    }}
                                                    // value={convertAMPMTo24hr(item.node.reminderTime) }
                                                     >
                                                     {
                                                        Array.from({length: 60}, (_, i) => {
                                                            let value = i.toString().length <=1?"0"+i:i
                                                            return <option key={i} value={value} className="open-400w-14s-22h grey-2-78787d-color mb-0">{value}</option>
                                                        })
                                                    }
                                                     </select>
                                                    </div>

                                                   
                                                    <select type='text' className="border-less-input w-100"
                                                    // readOnly={true}
                                                    value={item.node.reminderTime.split(" ")[1]}
                                                    onChange={(e) => {
                                                        changeTime(e.target.value,item.node.id,"ampm")
                                                    }}
                                                    // value={convertAMPMTo24hr(item.node.reminderTime) }
                                                     >
                                                        {
                                                            ["AM","PM"].map((item)=>{
                                                                return <option key={item} value={item} className="open-400w-14s-22h grey-2-78787d-color mb-0">{item}</option>
                                                            })
                                                        }
                                                     </select>
                                                </div>
                                            </div>
                                            {/* time list to be here */}
                                        </div>
                                    </div>
                                </div>
                            })
                        }
                        <div className='d-flex align-items-center gap-2 mt-4 '>
                            <p className={componentData.addList_class+" mb-0"} style={{color:"#00A095"}}>Add Reminder</p>
                            <img src={addIcon} alt='add-icon' className='icon-16' onClick={addChecklistReminderHandler}/>
                        </div>
                    </div>}
                </div>
                <div className='pt-4 px-3 px-lg-0'>
                    <button className='submit-button px-4 py-2' onClick={updateChecklistDataHandler}>
                        Save
                    </button>
                </div>
            </div>
        </div>
    }

    const updateChecklistDataHandler = () => {
        let variable = {
            checkListId:checklistData.id,
            checkListItems:checklistData.checklistitemSet.map(item=>{
                return {
                    id:item.node.id,
                    // name:item.node.itemName,
                    isChecked:item.node.isChecked
                }
            }),
            checkListReminder:checklistData.checklistreminderSet.map((item)=>{
                
                return {
                    id:item.node.id,
                    date:reconvertionDate(item.node.reminderDate).replaceAll("-","/"),
                    time:item.node.reminderTime
                }
            }),
            isAadhaar:checklistData.isAadhaar,
            isPrescription:checklistData.isPrescription
        }
        

        UpdateChecklistItemAndReminder(variable, (response) => {
            console.log(response)
            setDataModal(false)
        }
        , (err) => {
            console.log(err)
        })


    }

    return (
        <div className={'checklist-data-widget'}>
            {
                isDesktopOrLaptop ?
                    <div className={'desktop-data-checklist-modal'}>
                        <MDBModal isOpen={dataModal} position={'center'} centered={true}
                                  size={"fluid"}>
                            <MDBModalBody>
                                <ChecklistDataContent/>
                            </MDBModalBody>
                        </MDBModal>
                    </div>
                    :
                    <div className={''}>
                        <div className={'data-checklist-content'}>
                            <div>
                                <ChecklistDataContent/>
                            </div>
                        </div>
                    </div>
            }
        </div>
    )

}

export default ChecklistDataWidget
