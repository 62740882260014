import {
	ACTIVE_BUS_DETAIL,
	CHANGE_DATE_BUS_LISTING,
	DESTINATION_POINT,
	GET_CO_PASSENGERS_LIST,
	RAW_DATE,
	SEAT_ARRANGEMENT,
	SELECTED_SEAT_PRICE,
	SELECTED_SEATS_TYPE,
	SELECTED_SEATS,
	SET_AVAILABLE_BUS_LIST,
	SET_BOARDING_POINT,
	SET_BOOKING_DATA,
	SET_CONTACT_DATA,
	SET_COUPON_MESSAGE,
	SET_DATE,
	SET_DESTINATION_POINT_LIST,
	SET_DROPPING_POINT,
	SET_INSURE_AND_DONATE,
	SET_PASSENGER_COUNT,
	SET_PASSENGER_DATA,
	SET_PAYMENT_METHOD,
	SET_PICKUP_DROP_LIST,
	SET_SAVE_DETAIL_AND_INVOICE,
	SET_SOURCE_POINT_LIST,
	SET_USER_DATA,
	SOURCE_DESTINATION_ID,
	SOURCE_DESTINATION_POINTS,
	SOURCE_POINT,
	ACTIVE_BUS_FEMALE_AVAILABLE,
	CLEAR_BOOKING_DATA_ON_LOGOUT
} from "./index";

export const setSourcePoint = data => ({
	type: SOURCE_POINT,
	payload: {
		data: data
	}
})
export const setSourcePointList = (data) => {

	return {
		type: SET_SOURCE_POINT_LIST,
		payload: {
			data: data
		}
	}
}
export const setDestinationPoint = data => ({
	type: DESTINATION_POINT,
	payload: {
		data: data
	}
})
export const setDestinationPointList = data => ({
	type: SET_DESTINATION_POINT_LIST,
	payload: {
		data: data
	}
})

export const setSourceDestinationIdAction = data => ({
	type: SOURCE_DESTINATION_ID,
	payload: {
		data: data
	}
})

export const setAvailableBusList = data => ({
	type: SET_AVAILABLE_BUS_LIST,
	payload: {
		data: data
	}
})

export const setSourceAndDestination = data => ({
	type: SOURCE_DESTINATION_POINTS,
	payload: {
		data: data
	}
})

export const setRawDate = data => ({
	type: RAW_DATE,
	payload: {
		data: data
	}
})

export const setActiveBusDetail = data => ({
	type: ACTIVE_BUS_DETAIL,
	payload: {
		data: data
	}
})
export const setSelectedSeats = data => ({
	type: SELECTED_SEATS,
	payload: {
		data: data
	}
})
export const setSelectedSeatsType = data => ({
	type: SELECTED_SEATS_TYPE,
	payload: {
		data: data
	}
})

export const setSelectedSeatPrice = data => {

	return {
		type: SELECTED_SEAT_PRICE,
		payload: {
			data: data
		}
	}
}
export const setPassengerData = data => ({
	type: SET_PASSENGER_DATA,
	payload: {
		data: data
	}
})

export const setPickupPoint = data => ({
	type: SET_BOARDING_POINT,
	payload: {
		data: data
	}
})

export const setDropPoint = data => ({
	type: SET_DROPPING_POINT,
	payload: {
		data: data
	}
})

export const setSeatArrangementAction = data => ({
	type: SEAT_ARRANGEMENT,
	payload: {
		data: data
	}
})

export const setContactDataAction = data => ({
	type: SET_CONTACT_DATA,
	payload: {
		data: data
	}
})

export const setUserDataAction = data => ({
	type: SET_USER_DATA,
	payload: {
		data: data
	}
})

export const setBookingDataAction = data => ({
	type: SET_BOOKING_DATA,
	payload: {
		data: data
	}
})
export const setPickupAndDropPointListAction = data => {
	return {
		type: SET_PICKUP_DROP_LIST,
		payload: {
			data: data
		}
	}
}
export const setInsureAndDonateAction = data => {
	return {
		type: SET_INSURE_AND_DONATE,
		payload: {
			data: data
		}
	}
}

export const setSaveDetailAndInvoiceAction = data => {
	return {
		type: SET_SAVE_DETAIL_AND_INVOICE,
		payload: {
			data: data
		}
	}
}

export const setPaymentMethodAction = data => {
	return {
		type: SET_PAYMENT_METHOD,
		payload: {
			data: data
		}
	}
}

export const setDateAction = data => {
	return {
		type: SET_DATE,
		payload: {
			data: data
		}
	}
}
export const setPassengerCountAction = data => {
	return {
		type: SET_PASSENGER_COUNT,
		payload: {
			data: data
		}
	}
}
export const getCoPassengersListAction = (data) => {
	return {
		type: GET_CO_PASSENGERS_LIST,
		payload: {
			data: data
		}
	}
}
export const setChangedDateOnBusListingAction = (data) => {
	return {
		type: CHANGE_DATE_BUS_LISTING,
		payload: {
			data: data
		}
	}
}
export const setCouponMessageAction = (data) => {
	return {
		type: 'SET_COUPON_MESSAGE',
		payload: {
			data: data
		}
	}
}
export const clearBookingDataOnLogoutAction = () => {
	return {
		type: CLEAR_BOOKING_DATA_ON_LOGOUT,
	}
}

export const setActiveBusFemaleAvailableToStore = (data) => {
	return {
		type: ACTIVE_BUS_FEMALE_AVAILABLE,
		payload: {
			data: data
		}
	}
}