import React, {useEffect, useState} from 'react'
import '../ProfileDetail.scss'
import {useMediaQuery} from "react-responsive";
import {DeleteChecklistData} from "../../../services/api/DataUpdating";
import {useNavigate} from "react-router";
import ChecklistWidget from "../../../components/ChecklistNameWidget/ChecklistWidget";
import {getChecklistData} from "../../../services/api/DataFetching";
import {store} from "../../../index";
import {setCheckListNameAction} from "../../../store/Actions/CommonAction";

//assets
import deleteIcon from "../../../assets/icons/red-delete-icon.svg"
import cogoToast from "cogo-toast";
import {CustomToast} from "../../../components/CustomToast/CustomToast";


const ProfileChecklist = ({checklistNameModalFromParent}) => {
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const navigate = useNavigate();

    const [checklistName_modal, setChecklistName_modal] = useState(
        checklistNameModalFromParent ? checklistNameModalFromParent : false);
    const [checklist_local, setChecklist_local] = useState([]);
    const [checklistData_modal, setChecklistData_modal] = useState(false);
    
    const [selectedChecklistData, setSelectedChecklistData] = useState({
        name: "",
        id: "",
        checklistitemSet: [],
        checklistreminderSet: [],
        isAadhaar: false,
        isPrescription: false,
        isReminder: false,
    })

    let componentData
    if (isDesktopOrLaptop) {
        componentData = {
            title_class: "ubuntu-700w-20s-32h black-1E1E26-color mb-1",
            subTitle_modal_class: "ubuntu-500w-20s-32h black-1E1E26-color mb-0",
            subTitle_class: "open-700w-16s-24h grey-2-78787d-color mb-0",
            outlineCta_class: "open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer whitespace-nowrap",
            secondary_text_class: "open-700w-16s-24h grey-2-78787d-color mb-0",
            name_class: "open-600w-18s-28h black-1E1E26-color",
            icon_size: "icon-20"
        }
    } else {
        componentData = {
            title_class: "ubuntu-700w-18s-28h black-1E1E26-color mb-1",
            subTitle_modal_class: "open-700w-16s-24h black-1E1E26-color mb-0",
            subTitle_class: "open-400w-12s-18h grey-2-78787d-color",
            outlineCta_class: "d-flex align-items-center " +
                " open-400w-12s-18h teal-2-00A095-color cursor-pointer ",
            filledCta_class: "",
            secondary_text_class: "open-400w-14s-22h grey-2-78787d-color mb-0",
            name_class: "open-700w-16s-24h black-1E1E26-color mb-0",
            icon_size: "icon-16"
        }
    }

    useEffect(() => {

        fetchingChecklistData()

        return () => {

        };
    }, [checklistName_modal,checklistData_modal]);

    const fetchingChecklistData = () => {
        getChecklistData(response => {
            setChecklist_local(response)
        }, error => {
            if(error){
                cogoToast.error(
                    <CustomToast
                        heading={"Error"}
                        message={error[0]?.message || error.toString()}
                        type={"error"}
                    />,
                );
            }
        })
    }

    const createChecklistHandler = () => {
        setSelectedChecklistData({
            name: "",
            id: "",
            checklistitemSet: [],
            checklistreminderSet: [],
            isAadhaar: false,
            isPrescription: false,
            isReminder: false,
        })
        setChecklistName_modal(true)
    }

    const deleteHandler = (id) => {
        DeleteChecklistData({id: id}, (response) => {
            setChecklistData_modal(false);
            fetchingChecklistData()


        }, (err) => {
            if(err){
                cogoToast.error(
                    <CustomToast
                        heading={"Error"}
                        message={err[0]?.message || err.toString()}
                        type={"error"}
                    />,
                );
            }
        })
    }

    const selecteChecklistHandler = (id, name) => {
        store.dispatch(setCheckListNameAction(name))
        checklist_local.filter(item => {
            if (item.id === id) {
                setSelectedChecklistData({
                    name: item.name,
                    id: item.id,
                    checklistitemSet: item.checklistitemSet.edges,
                    checklistreminderSet: item.checklistreminderSet.edges,
                    isAadhaar: item.isAadhaar,
                    isPrescription: item.isPrescription,
                    isReminder: item.isReminder
                })
            }
        })
        if (isDesktopOrLaptop) {
            setChecklistData_modal(true)

        } else {
            navigate(`/checklist/${id}`)
        }

    }

    return (
        <div>
            <div className={'profile-checklist-component '}>
                {/* heading */}
                {isDesktopOrLaptop && <div className={'d-flex justify-content-between align-items-center'}>
                    {/*left */}
                    <div className={'d-flex w-100'}>

                        <div className={'ml-3 ml-lg-0 w-100'}>
                            <div className={'d-flex justify-content-between align-items-center w-100'}>
                                <p className={componentData.title_class}>Checklist</p>
                            </div>
                            <p className={componentData.subTitle_class}>Create custom travel checklist</p>
                        </div>
                    </div>

                    {/* right */}
                    {isDesktopOrLaptop &&
                        <div className={componentData.outlineCta_class} onClick={createChecklistHandler}>
                            <p className={'mb-0 '}>Create Checklist</p>
                        </div>}
                </div>}

                {/* checklist */}
                {
                    checklist_local.length > 0 &&
                    <div className={'checklist-item-wrap mt-3 px-3'}>
                        {
                            checklist_local.map((item, index) => {
                                return <div key={index}
                                            onClick={() => selecteChecklistHandler(item.id, item.name)}
                                            className={'checklist-item cursor-pointer ' +
                                                'd-flex justify-content-between align-items-start w-100'}>
                                    <div>
                                        <p className={componentData.name_class}>{item.name}</p>
                                        {item.checklistitemSet.length > 0 &&
                                            <p className={componentData.secondary_text_class}>{item.checklistitemSet.length > 2 ? (item.checklistitemSet.slice(0, 1)).join + "..." : (item.checklistitemSet).join}</p>}
                                    </div>
                                    <img src={deleteIcon} alt={'delete'} className={'delete-icon-wrap icon-20'}
                                         onClick={() => deleteHandler(item.id)}
                                    />
                                </div>
                            })

                        }

                    </div>
                }
            </div>

            {/* modal */}
            <ChecklistWidget
                nameModal={checklistName_modal}
                setNameModal={setChecklistName_modal}
                dataModal={checklistData_modal}
                setDataModal={setChecklistData_modal}
                selectedChecklistData={selectedChecklistData}
                setSelectedChecklistData={setSelectedChecklistData}
            />

        </div>
    )
}

export default ProfileChecklist
