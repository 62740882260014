import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import Loader from "../../../components/Loader/Loader";

const Leaders = [
  {
    Image: "https://nuego-cms-spaces.sgp1.cdn.digitaloceanspaces.com/cms-web/staging/DevndraChawla.jpg",
    name: "Devndra Chawla",
    position: "CEO",
    linkedin: "https://in.linkedin.com/in/devendrachawla"
  },
  {
    Image: "https://nuego-cms-spaces.sgp1.cdn.digitaloceanspaces.com/cms-web/staging/ourLeader-1.png",
    name: "Sumit Mittal",
    position: "COO",
    linkedin: "https://in.linkedin.com/in/sumit-mittal-b57b1492"
  },
  {
    Image: "https://nuego-cms-spaces.sgp1.cdn.digitaloceanspaces.com/cms-web/staging/ourLeader-2.png",
    name: "Rajani Tewari",
    position: "CHRO",
    linkedin: "https://in.linkedin.com/in/rajanitewari"
  },
  {
    Image: "https://nuego-cms-spaces.sgp1.cdn.digitaloceanspaces.com/cms-web/staging/VijeetShekhawat.jpg",
    name: "Vijeet Shekhawat",
    position: "CRO",
    linkedin: "https://in.linkedin.com/in/vijeet"
  },
];

const OurLeader = () => {


  const isDesktopOrLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });


  const [LoadingSpiner, setLoadingSpiner] = useState({ value: false, Response: "pending" })
  const [OurLeadersData, setOurLeadersData] = useState([])



  let pendingObject = { value: true, Response: "pending" }
  let sucessObject = { value: false, Response: "success" }
  let errorObject = { value: false, Response: "error" }




  useEffect(() => {

    setLoadingSpiner(pendingObject)

    fetch(`${process.env.REACT_APP_CMS_PROD_API}api/our-leaders?populate=*&sort=rank:asc`,
      {
        headers: {
          'Content-Type': 'application/json',
        },

      })
      .then((res) => res.json())

      .then(
        (res) => {
          if (res?.error) {
            setLoadingSpiner(errorObject)

          } else {
            setLoadingSpiner(sucessObject)
            setOurLeadersData(res?.data)
          }

          return res;

        })

      .catch((err) => {
        setLoadingSpiner(errorObject)
        return err;

      });

  }, [])

  const linkedin = "https://nuego-cms-spaces.sgp1.cdn.digitaloceanspaces.com/cms-web/staging/linkedin.svg"



  return (
    <>
      {LoadingSpiner?.value == true ? <Loader />
        : <div className="our-leaders">
          <p className={isDesktopOrLaptop ? "text-center our-leader-title pt-5" : " our-leader-title px-3 pt-3"}>Our Leaders</p>

          {LoadingSpiner?.Response != "error" ? <div className={isDesktopOrLaptop ? "row our-leaders-part pb-5 d-flex p-2 m-0  justify-content-center" : "our-leaders-part d-flex  px-3 pb-5"}>
            {OurLeadersData?.map((data, index) => {
              return <div key={index} className={isDesktopOrLaptop ? " our-leader-box p-2" : "our-leader-box d-flex flex-column p-3 "}>
                <img src={data?.attributes?.profilePic} alt={data?.attributes?.profilePicAltText} loading="lazy"/>
                <a href={data?.attributes?.linkedin} target="_blank" rel="nofollow" className="lindedIn"><img

                  src={linkedin}
                  alt="Linkedin"
                  loading="lazy"
                /></a>
                <div className={isDesktopOrLaptop ? "" : ""}>
                  <p className={isDesktopOrLaptop ? "leaders-name pt-2" : "leaders-name"}>{data?.attributes?.name}</p>
                  <p className="leaders-position">{data?.attributes?.designation}</p>
                </div>
              </div>
            })}
          </div> :
            <div className={isDesktopOrLaptop ? "row our-leaders-part pb-5 d-flex p-2 m-0  justify-content-center" : "our-leaders-part d-flex flex-column px-3 pb-5"}>
              {Leaders.map((data, index) => (
                <div key={index} className={isDesktopOrLaptop ? "our-leader-box p-2" : "our-leader-box d-flex p-3 "}>
                  <img src={data.Image} loading="lazy" alt="alt" />
                  <a href={data.linkedin} rel="nofollow"  target="_blank" className="lindedIn"><img

                    src={linkedin}
                    alt="Linkedin"
                    loading="lazy"
                  /></a>
                  <div className={isDesktopOrLaptop ? "" : ""}>
                    <p className={isDesktopOrLaptop ? "leaders-name pt-2" : "leaders-name"}>{data.name}</p>
                    <p className="leaders-position  ">{data.position}</p>
                  </div>
                </div>
              ))}
            </div>}
        </div>
      }
    </>
  );
};

export default OurLeader;
