import graphql from 'babel-plugin-relay/macro';
import {commitMutation} from 'react-relay';
import environment from "../../environment";

const mutation = graphql`
	mutation UpdateCoPassengerMutation(
		$age: Int!
        $gender:GenderEnum!
        $mobileNumber:String!
        $name:String!
        $id:ID!
	) {
		updateCoPassenger(
	    age:$age
        gender:$gender
        mobileNumber:$mobileNumber
        name:$name
        id:$id
	) {
		    message
			coPassenger {
			    name
			    age
			    gender
			    mobileNumber
			    id
}
		}
	}
	`

export default (data, callback, errCallback) => {
	const variables = {
		age:data.age,
		gender:data.gender,
		mobileNumber:data.mobileNumber,
		name:data.name,
		id:data.id
	}
	commitMutation(
		environment,
		{
			mutation,
			variables,
			onCompleted: (response, err) => {
				if (response.updateCoPassenger !== null) {
					callback(response)
				} else {
					errCallback(err[0].message);
				}
			}
		}
	)
}