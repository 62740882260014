/**
 * @generated SignedSource<<a9ceb815c6c1373285949a68bcee7819>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* eslint-disable */

'use strict';

var node = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "CoPassengerNode",
    "kind": "LinkedField",
    "name": "coPassengers",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "age",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "gender",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mobileNumber",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "GetCoPassengerListQuery",
    "selections": (v0/*: any*/),
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "GetCoPassengerListQuery",
    "selections": (v0/*: any*/)
  },
  "params": {
    "cacheID": "0e245b0e74120397801c80cd18f36772",
    "id": null,
    "metadata": {},
    "name": "GetCoPassengerListQuery",
    "operationKind": "query",
    "text": "query GetCoPassengerListQuery {\n  coPassengers {\n    id\n    name\n    age\n    gender\n    mobileNumber\n  }\n}\n"
  }
};
})();

node.hash = "9f9208727a728480ef59e0be8c687a9a";

module.exports = node;
