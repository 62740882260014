import React, {useEffect, useState} from 'react'
import './ChangeBookingModalComponent.scss'
import {MDBModal, MDBModalBody} from "mdbreact";
//imports
import CancelBooking from "./SubScreens/CancelBooking";
import {useMediaQuery} from "react-responsive";
import {getCancelDetailsData, getCancelPartialDetailsData} from "../../services/api/DataFetching";
import CancelBookingPayment from "./SubScreens/CancelBookingPayment";
import RescheduleBooking from "./SubScreens/RescheduleBooking";
import {useNavigate} from "react-router";
import {setDateAction, setRawDate} from "../../store/Actions/BookingAction";
import {store} from "../../index";
import {convertToDateFormat, reconvertionDate} from "../../utils/utils";
import {setRescheduleAction} from "../../store/Actions/CommonAction";
import {cancelSinglePassenger, ModifyBookingData, PartialCancelBooking} from "../../services/api/DataUpdating";
import ModifyBooking from "./SubScreens/ModifyBooking";


//icons
import grayCloseIcon from "../../assets/icons/cross-gray.svg"
import activeCancelIcon from "../../assets/icons/cancel-active-icon.svg"
import activeRescheduleIcon from "../../assets/icons/reshedule-active-icon.svg"
import activeModifyIcon from "../../assets/icons/modify-active-icon.svg"
import cancelIcon from "../../assets/icons/cancel-icon.svg"
import rescheduleIcon from "../../assets/icons/reschedule-icon.svg"
import modifyIcon from "../../assets/icons/modify-icon.svg"

const ChangeBookingModalComponent = (props) => {
    const {activeModal, setActiveModal, bookingId, referenceNo} = props;
    const isDesktopOrLaptop = useMediaQuery({minWidth: 1024});
    const navigate = useNavigate();

    // states -----
    // common
    const [activeTab, setActiveTab] = useState("cancel");
    // cancel
    const [cancelDetails, setCancelDetails] = useState({});
    const [refundModal, setRefundModal] = useState(false);
    const [activeReason, setActiveReason] = useState("");
    const [activePassengerList, setActivePassengerList] = useState([]);
    const [activePayment, setActivePayment] = useState("");
    const [remarks, setRemarks] = useState("");
    const [refundStatus, setRefundStatus] = useState("");
    const [errorMessage, setErrorMessage] = useState("");
    // modify
    const [modifyStatus, setModifyStatus] = useState("");

    // useEffect
    useEffect(() => {
        setModifyStatus("")
        return () => {

        };
    }, []);

    useEffect(() => {

        if (activeTab === "cancel" && Object.keys(cancelDetails).length === 0 && activePassengerList.length === 0) {
            let variable = {
                bookingId: bookingId,
                passengerIds: activePassengerList
            }
            getCancelDetailsData(variable, (data) => {
                setCancelDetails(data)
            })
        }

    }, [activeTab]);

    useEffect(() => {

        if (activeTab === "cancel" && activePassengerList.length > 0) {
            let variable = {
                bookingId: bookingId,
                passengerIds: activePassengerList
            }
            getCancelPartialDetailsData(variable, (data) => {
                setCancelDetails(data)
            })
        }
    }, [activePassengerList.length]);

    let pageConstant
    if (isDesktopOrLaptop) {
        pageConstant = {
            tab_class: 'open-600w-16s-24h mb-0',
            tabWrap_class: 'pb-4'
        }
    } else {
        pageConstant = {
            tab_class: 'open-600w-16s-24h mb-0',
            tabWrap_class: 'pb-0'
        }
    }

    // methods -----
    const cancelSubmitHandler = () => {
        setRefundModal(true)
    }

    const cancelPaymentSubmitHandler = (refundTypeValue) => {
        // setRefundModal(false)
        let variable

        if (cancelDetails.booking?.passengerdetailSet.length > 1 && activePassengerList.length !== cancelDetails.booking?.passengerdetailSet.length) {
            variable = {
                "bookingId": bookingId,
                "cancellationReason": activeReason,
                "cancellationRemark": remarks,
                "paymentType": activePayment,
                "passengerIds": activePassengerList,
                "refundType": refundTypeValue
            }
            PartialCancelBooking(variable, (data) => {

                setRefundStatus("success")
            }, (error) => {
                setRefundStatus("fail")

                setErrorMessage(error)
            })

        } else {
            variable = {
                "bookingId": bookingId,
                "cancellationReason": activeReason,
                "cancellationRemark": remarks,
                "paymentType": activePayment,
                "refundType": refundTypeValue
            }
            cancelSinglePassenger(variable, (data) => {
                setRefundStatus("success")
            }, (error) => {
                setRefundStatus("fail")

                setErrorMessage(error)
            })
        }
    }

    const rescheduleSubmitHandler = (date) => {

        let actualDateFormat = (convertToDateFormat(reconvertionDate(date)))
        store.dispatch(setDateAction({date: date}))
        store.dispatch(setRawDate(actualDateFormat.toISOString()));
        store.dispatch(setRescheduleAction(true));
        setTimeout(() => {
            navigate('/booking', {replace: false})
        }, 1000)

    }

    const modifySubmitHandler = (data) => {
        ModifyBookingData(data, (data) => {
            setModifyStatus("success")
        }, (error) => {
            setModifyStatus("fail")
            setErrorMessage(error)
        })
    }

    return (
        <div className={'change-booking-modal-component'}>
            <MDBModal isOpen={activeModal} centered={true}
                      size={'lg'}
                      position={'center'}>
                <MDBModalBody>
                    <div className={'content-area'}>

                        {refundModal ? <div className={'padding-top-side-40'}> {/* refund modal */}
                                <CancelBookingPayment
                                    cancelDetails={cancelDetails}
                                    activePayment={activePayment}
                                    setActivePayment={setActivePayment}
                                    setModal={setRefundModal}
                                    refundStatus={refundStatus}
                                    refundErrorMessage={errorMessage}
                                    cancelPaymentSubmitHandler={(args) => cancelPaymentSubmitHandler(args)}/>
                            </div>
                            :
                            <div> {/* common change booking modal */}
                                {/* common */}
                                <div
                                    className={'d-flex justify-content-between align-items-start w-100 padding-top-side-40'}>
                                    <p className={'ubuntu-700w-24s-32h margin-bottom-32'}>Change Booking</p>
                                    <img src={grayCloseIcon} onClick={() => setActiveModal(false)}
                                         className={'icon-24 cursor-pointer'} alt={'alt'}/>
                                </div>
                                <div className={'d-flex flex-wrap-12 padding-x-40 ' + (pageConstant.tabWrap_class)}>
                                    <div onClick={() => setActiveTab("cancel")}
                                         className={" d-flex justify-content-center " + (activeTab === "cancel" ? "active-tab-local" : "inactive-tab-local")}>
                                        <img src={activeTab === "cancel" ? activeCancelIcon : cancelIcon} alt={'alt'}
                                             className={'icon-24 mr-2'}/>
                                        <p className={pageConstant.tab_class}>Cancel</p>
                                    </div>
                                    <div onClick={() => setActiveTab("reschedule")}
                                         className={" d-flex justify-content-center " + (activeTab === "reschedule" ? "active-tab-local" : "inactive-tab-local")}>
                                        <img src={activeTab === "reschedule" ? activeRescheduleIcon : rescheduleIcon}
                                             alt={'alt'} className={'icon-24 mr-2'}/>
                                        <p className={pageConstant.tab_class}>Reschedule</p>
                                    </div>
                                    <div onClick={() => setActiveTab("modify")}
                                         className={" d-flex justify-content-center " + (activeTab === "modify" ? "active-tab-local" : "inactive-tab-local")}>
                                        <img src={activeTab === "modify" ? activeModifyIcon : modifyIcon} alt={'alt'}
                                             className={'icon-24 mr-2'}/>
                                        <p className={pageConstant.tab_class}>Modify</p>
                                    </div>
                                </div>
                                <div className={'margin-bottom-32'}>
                                    {
                                        activeTab === "cancel" &&
                                        <CancelBooking cancelDetails={cancelDetails} submitHandler={cancelSubmitHandler}
                                                       activeReason={activeReason}
                                                       setActiveReason={setActiveReason}
                                                       remarks={remarks}
                                                       setRemarks={setRemarks}
                                                       activePassengerList={activePassengerList}
                                                       setActivePassengerList={setActivePassengerList}
                                                       bookingId={bookingId}
                                        />
                                    }
                                    {
                                        activeTab === "reschedule" &&
                                        <RescheduleBooking bookingId={bookingId}
                                                           submitHandler={(date) => rescheduleSubmitHandler(date)}
                                        />
                                    }
                                    {
                                        activeTab === "modify" &&
                                        <ModifyBooking referenceNo={referenceNo} bookingId={bookingId}
                                                       modifyStatus={modifyStatus} errorMessage={errorMessage}
                                                       submitHandler={(data) => modifySubmitHandler(data)}
                                        />
                                    }
                                </div>

                            </div>}

                    </div>
                </MDBModalBody>
            </MDBModal>
        </div>
    )
}

export default ChangeBookingModalComponent
