import React, { useEffect, useState } from "react";
import "./CoPassengerDetailForm.scss";
import { useMediaQuery } from "react-responsive";
import { getValues } from "../../utils/utils";
import cogoToast from "cogo-toast";
import { CustomToast } from "../CustomToast/CustomToast";
import {
	CreateCoPassengerData,
	UpdateCoPassengerData,
} from "../../services/api/DataUpdating";

//icons
import profileIcon from "../../assets/icons/profile-icon.svg";

const CoPassengerDetailForm = (props) => {
	const isDesktopOrLaptop = useMediaQuery({ minWidth: 1024 });
	const {
		editMode,
		setEditMode,
		coPassengerData,
		closeModal,
		coPassengerList_local,
	} = props;

	// need to replace based on the mutation
	const passengerGenderList = getValues("ProfileGenderEnum!");

	let componentData;
	if (isDesktopOrLaptop) {
		componentData = {
			title_class: "ubuntu-700w-20s-32h black-1E1E26-color",
			subTitle_class: "open-700w-16s-24h grey-2-78787d-color",
			outlineCta_class:
				"open-600w-18s-24h teal-2-00A095-color outline-button mb-0 cursor-pointer",
			label_class: "open-700w-16s-24h grey-2-78787d-color",
			input_class: "open-600w-18s-28h black-1E1E26-color",
			error_class: "red-F85959-color",
			inputMarginBottom_class: " mb-4 ",
			submitButtonWrap_class: " d-flex justify-content-end w-100 ",
		};
	} else {
		componentData = {
			title_class: "ubuntu-700w-18s-28h black-1E1E26-color",
			subTitle_class: "open-400w-18s-28h grey-2-78787d-color",
			outlineCta_class: "open-600w-18s-24h teal-2-00A095-color cursor-pointer ",
			filledCta_class: "",
			label_class: "open-400w-14s-22h grey-2-78787d-color",
			input_class: "open-600w-16s-24h black-1E1E26-color",
			error_class: "red-F85959-color",
			inputMarginBottom_class: " mb-3 ",
			submitButtonWrap_class: " w-100 d-flex justify-content-center ",
		};
	}

	const [coPassengerData_local, setCoPassengerData_local] = useState({});
	const [error, setError] = useState({
		name: false,
		mobileNumber: false,
		age: false,
	});
	const [touched, setTouched] = useState([]);

	// useEffect
	useEffect(() => {
		let tempObj = {
			name: coPassengerData?.name ? coPassengerData?.name : "",
			age: coPassengerData?.age ? coPassengerData.age : "",
			gender: coPassengerData?.gender ? coPassengerData.gender : "M",
			mobileNumber: coPassengerData?.mobileNumber
				? coPassengerData.mobileNumber
				: "",
		};
		setCoPassengerData_local(tempObj);
	}, []);

	// methods
	const onChangeHandler = (e, field) => {
		if (field !== "gender") {
			e.preventDefault();
		}

		if (!touched.includes(field)) {
			setTouched([...touched, field]);
		}

		if (field === "name") {
			if (e.target.value.trim(" ", "") === "") {
				setError({ ...error, [field]: "error" });
				setCoPassengerData_local({
					...coPassengerData_local,
					[field]: e.target.value,
				});
			} else {
				if (e.target.value.match(/\d+/g) === null) {
					setError({ ...error, [field]: null });
					setCoPassengerData_local({
						...coPassengerData_local,
						[field]: e.target.value,
					});
				}
			}
		}

		if (field === "age") {
			setCoPassengerData_local({
				...coPassengerData_local,
				[field]: e.target.value,
			});
			if (parseInt(e.target.value) < 6 || parseInt(e.target.value) > 100) {
				setError({ ...error, [field]: "error" });
			} else {
				setError({ ...error, [field]: null });
				setCoPassengerData_local({
					...coPassengerData_local,
					[field]: e.target.value,
				});
			}
		}

		if (field === "gender") {
			setCoPassengerData_local({ ...coPassengerData_local, [field]: e });
		}

		if (field === "mobileNumber") {
			if (e.target.value.length < 11) {
				setCoPassengerData_local({
					...coPassengerData_local,
					[field]: e.target.value,
				});
			}

			if (e.target.value.length !== 10) {
				setError({ ...error, [field]: "error" });
			} else {
				setError({ ...error, [field]: null });
			}
		}
	};

	const onSubmitHandler = () => {
		let errorLength = Object.values(error).filter((item) => item === "error")
			.length;

		if (coPassengerData_local.gender === null) {
			cogoToast.error(
				<CustomToast
					type={"error"}
					message={"Please update the Gender field"}
					heading={"Error"}
				/>,
				{ position: "top-center" },
			);
		} else {
			if (errorLength !== 0) {
				cogoToast.error(
					<CustomToast
						type={"error"}
						message={"Please update the highlighted fields"}
						heading={"Error"}
					/>,
					{ position: "top-center" },
				);
			}

			if (editMode) {
				let variables = {
					id: coPassengerData.id,
					name: coPassengerData_local.name,
					age: coPassengerData_local.age,
					gender: coPassengerData_local.gender,
					mobileNumber: coPassengerData_local.mobileNumber,
				};

				UpdateCoPassengerData(
					variables,
					(response) => {
						setEditMode(false);
						closeModal();
					},
					(error) => {
						if (error) {
							cogoToast.error(
								<CustomToast
									heading={"Error"}
									message={error[0]?.message || error.toString()}
									type={"error"}
								/>,
							);
						}
					},
				);
			} else {
				if (touched.length === 0) {
					cogoToast.error(
						<CustomToast
							type={"error"}
							message={"Please update any field to save changes"}
							heading={"Error"}
						/>,
						{ position: "top-center" },
					);
				}
				let variables = {
					name: coPassengerData_local.name,
					age: parseInt(coPassengerData_local.age),
					gender: coPassengerData_local.gender,
					mobileNumber: coPassengerData_local.mobileNumber,
				};
				if (
					coPassengerList_local.length>0 &&
					coPassengerList_local.find(
						(item) =>
							item.mobileNumber !== coPassengerData_local.mobileNumber &&
							item.name !== coPassengerData_local.name,
					)
				) {
					CreateCoPassengerData(
						variables,
						(response) => {
							closeModal();
						},
						(error) => {
							if (error) {
								cogoToast.error(
									<CustomToast
										heading={"Error"}
										message={error[0]?.message || error.toString()}
										type={"error"}
									/>,
								);
							}
							closeModal();
						},
					);
				} 
				else if(Object.keys(coPassengerData_local).length){
					CreateCoPassengerData(
						variables,
						(response) => {
							closeModal();
						},
						(error) => {
							if (error) {
								cogoToast.error(
									<CustomToast
										heading={"Error"}
										message={error[0]?.message || error.toString()}
										type={"error"}
									/>,
								);
							}
							closeModal();
						},
					);


				}
				else {
					cogoToast.error(
						<CustomToast
							heading={"Error"}
							message={"User details already exists"}
							type={"error"}
						/>,
					);
				}
			}
		}
	};

	const handleTouch = (field) => {
		if (field === "age" && (error.name || coPassengerData.name === "")) {
			cogoToast.error(
				<CustomToast
					type={"error"}
					message={"Please fill Name"}
					heading={"Error"}
				/>,
				{ position: "top-center" },
			);
		}

		if (!touched.includes(field)) {
			setTouched([...touched, field]);
		}
	};

	const handleBlur = (field) => {
		if (field === "mobileNumber") {
			if (coPassengerData_local.mobileNumber.length !== 10) {
				setError({ ...error, [field]: "error" });
			} else {
				setError({ ...error, [field]: null });
			}
		}
		if (touched.includes(field)) {
			if (coPassengerData_local[field] === "")
				setError({ ...error, [field]: "error" });
		}
	};

	return (
		<div className={"co-passenger-detail-form-component"}>
			{/*heading */}
			<div className={"d-flex align-items-center py-3 px-3 "}>
				<img src={profileIcon} className={"icon-24 mr-1"} alt={"alt"} />
				<p className={"ubuntu-700w-18s-28h black-1E1E26-color mb-0"}>
					{editMode ? "Update Co-Passenger" : "Add Co-Passenger"}
				</p>
			</div>

			{/* form */}
			<div className={"px-3 px-lg-0"}>
				<div
					className={
						"row " +
						(isDesktopOrLaptop ? componentData.inputMarginBottom_class : "")
					}
				>
					<div className='col-lg-6 col-12 mb-lg-0 mb-3'>
						{/* name */}
						<p className={componentData.label_class}>Name </p>
						<div
							className={
								"common-input-wrap input-name " +
								(error.name === "error" && "common-input-wrap-error")
							}
							onClick={() => handleTouch("name")}
						>
							<input
								className='border-less-input w-100'
								value={coPassengerData_local.name}
								onBlur={() => handleBlur("name")}
								onChange={(e) => {
									// case 1 a
									onChangeHandler(e, "name");
								}}
								type={"text"}
							/>
						</div>
						{error.name === "error" && (
							<div>
								<p className={componentData.error_class}>Invalid Name</p>
							</div>
						)}
					</div>
					<div className='col-lg-6 col-12'>
						<div className={"d-flex justify-content-between "}>
							<div className={" mb-md-3 "}>
								<p className={componentData.label_class}>Age</p>
								<div
									className={
										"common-input-wrap input-age " +
										(error.age === "error" && "common-input-wrap-error")
									}
									onClick={() => handleTouch("age")}
								>
									<input
										className='border-less-input'
										pattern='/^-?\d+\.?\d*$/'
										onBlur={() => handleBlur("age")}
										value={coPassengerData_local.age}
										min='0'
										max={"100"}
										onChange={(e) => {
											// case 1 a
											onChangeHandler(e, "age");
										}}
										type={"number"}
									/>
								</div>
								{error.age === "error" && (
									<div>
										<p className={componentData.error_class}>Invalid Age</p>
									</div>
								)}
							</div>
							<div className={"ml-3 mb-md-3 w-100"}>
								<p className={componentData.label_class}>Gender</p>
								<div className={"d-flex"}>
									<div
										onClick={() => {
											onChangeHandler("M", "gender");
										}}
										className={
											"gender-box-left " +
											(coPassengerData_local?.gender === "M"
												? "teal-22BBB0-color active-tab"
												: "opacity-60")
										}
									>
										<p className={"open-600w-16s-24h "}>Male</p>
									</div>
									<div
										onClick={() => {
											onChangeHandler("F", "gender");
										}}
										className={
											"gender-box-right " +
											(coPassengerData_local?.gender === "F"
												? "teal-22BBB0-color active-tab"
												: "opacity-60")
										}
									>
										<p className={"open-600w-16s-24h "}>Female</p>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				{/* phone number */}
				<div className='d-flex mt-4 half-wd-desktop'>
					<div className={"mr-3"}>
						<p className={componentData.label_class}>Code</p>

						<div className={"common-input-wrap "}>
							<select className='border-less-input select-element'>
								<option>+91</option>
								<option>+90</option>
							</select>
						</div>
					</div>
					<div className={"w-100"}>
						<p className={componentData.label_class}>Phone Number</p>

						<div
							onClick={() => handleTouch("mobileNumber")}
							className={"common-input-wrap "}
						>
							<input
								min='0'
								className='border-less-input w-100 '
								type={"number"}
								value={coPassengerData_local.mobileNumber}
								// pattern="[0-9]{10}"
								onKeyPress={(e) =>
									e.target.value.length === 10 ? e.preventDefault() : null
								}
								onBlur={() => handleBlur("mobileNumber")}
								onChange={(e) => onChangeHandler(e, "mobileNumber")}
							/>
						</div>
						{error.mobileNumber === "error" && (
							<div>
								<p className={componentData.error_class}>
									Invalid Mobile Number
								</p>
							</div>
						)}
					</div>
				</div>
			</div>

			{/* submit button */}
			<div className={componentData.submitButtonWrap_class}>
				<button
					onClick={() => onSubmitHandler()}
					disabled={
						editMode
							? Object.values(error).filter((e) => e !== null && e !== false)
									.length !== 0
							: touched.length < 3 ||
							  Object.values(error).filter((e) => e === null).length < 3
					}
					className={
						"submit-button mt-4 cursor-pointer text-center open-600w-16s-24h"
					}
				>
					Save Changes
				</button>
			</div>
		</div>
	);
};

export default CoPassengerDetailForm;
